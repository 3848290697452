import { Text } from "@clipboard-health/ui-react";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Card, CardContent, Stack } from "@mui/material";

interface ActivatedPlacementCandidateProps {
  hasIntroductionUrl: boolean;
}

export function ActivatedPlacementCandidate(props: ActivatedPlacementCandidateProps) {
  const { hasIntroductionUrl } = props;

  return (
    <Card sx={{ position: "fixed", bottom: 1, left: 1, right: 1, zIndex: 1 }} variant="outlined">
      <CardContent
        sx={{
          "&.MuiCardContent-root": {
            padding: 2,
          },
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <VerifiedIcon color="success" fontSize="large" />
          <Text variant="body2" color="text.primary">
            {hasIntroductionUrl
              ? "Congratulations! Since your permanent jobs profile has a video, it is being prioritized to local workplaces. Check the jobs page for updates."
              : "Congratulations! You've created your permanent jobs profile. Check the jobs page for updates."}
          </Text>
        </Stack>
      </CardContent>
    </Card>
  );
}
