import { LoadingButton, Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { zodResolver } from "@hookform/resolvers/zod";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Accordion, AccordionDetails, AccordionSummary, Button, Stack } from "@mui/material";
import { InfoTooltip } from "@src/appV2/lib/Tooltip/InfoTooltip";
import { formatDistanceInMiles } from "@src/appV2/lib/utils/distance";
import { type Worker } from "@src/appV2/Worker/api/types";
import { useState } from "react";
import { useForm } from "react-hook-form";

import { jobTypeOptions, shiftTypeOptions } from "../constants";
import {
  type JobType,
  type PreferredCandidateArea,
  type PreferredCandidateAreaForm,
  type ShiftNameType,
} from "../types";
import { AddressSelectionDialog } from "./AddressSelectionDialog";
import { type JobPreferenceFormData, jobPreferenceSchema } from "./JobPreferenceFormSchema";
import { PreferenceFormField } from "./PreferenceFormField";

interface JobPreferenceFormProps {
  initialJobTypes?: JobType[];
  initialShiftTypes?: ShiftNameType[];
  initialAddressDetails?: PreferredCandidateArea;
  worker: Worker;
  onSave: (data: JobPreferenceFormData) => Promise<void>;
}

export function JobPreferenceForm(props: JobPreferenceFormProps) {
  const {
    initialJobTypes = [],
    initialShiftTypes = [],
    initialAddressDetails,
    worker,
    onSave,
  } = props;
  const hasInitialPreferences =
    initialJobTypes.length > 0 && initialShiftTypes.length > 0 && isDefined(initialAddressDetails);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isExpanded, setIsExpanded] = useState(!hasInitialPreferences);
  const addressDialogModalState = useModalState();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    watch,
  } = useForm<JobPreferenceFormData>({
    resolver: zodResolver(jobPreferenceSchema),
    defaultValues: {
      jobTypes: initialJobTypes,
      shiftTypes: initialShiftTypes,
      addressObject: initialAddressDetails,
    },
  });

  const addressObject = watch("addressObject");

  const handleAddressSelect = (selectedAddressPreference: PreferredCandidateAreaForm) => {
    setValue(
      "addressObject",
      {
        address: selectedAddressPreference.address,
        location: {
          type: "Point",
          coordinates: [
            selectedAddressPreference.location.lng,
            selectedAddressPreference.location.lat,
          ],
        },
        distance: selectedAddressPreference.distance,
      },
      { shouldValidate: true }
    );
    addressDialogModalState.closeModal();
  };

  if (!isDefined(worker)) {
    return null;
  }

  return (
    <form
      onSubmit={handleSubmit(async (data: JobPreferenceFormData) => {
        await onSave(data);
        setIsEditMode(false);
        setIsExpanded(false);
      })}
    >
      <Accordion expanded={isEditMode || isExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ mx: 1 }} />}
          onClick={(event) => {
            event.stopPropagation();
            if (!isEditMode) {
              setIsExpanded((previous) => !previous);
            }
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
            <Stack direction="row" alignItems="center">
              <Text variant="h4">Preferences</Text>
              <InfoTooltip
                infoText="Let local workplaces know what kind of jobs you prefer"
                fontSize="small"
              />
            </Stack>
            {hasInitialPreferences && !isEditMode && (
              <Button
                size="small"
                startIcon={<EditRoundedIcon fontSize="small" />}
                onClick={(event) => {
                  event.stopPropagation();
                  setIsEditMode(true);
                }}
              >
                Edit
              </Button>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            <PreferenceFormField
              control={control}
              name="jobTypes"
              label="Job types I'm interested in"
              options={jobTypeOptions}
              error={errors.jobTypes?.message}
              disabled={!isEditMode && hasInitialPreferences}
            />
            <PreferenceFormField
              control={control}
              name="shiftTypes"
              label="Shift types I'm interested in"
              options={shiftTypeOptions}
              error={errors.shiftTypes?.message}
              disabled={!isEditMode && hasInitialPreferences}
            />
            <Stack spacing={0.5}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Text variant="body1">Where I&apos;m willing to work</Text>
                <Button
                  size="small"
                  startIcon={<LocationOnIcon />}
                  disabled={!isEditMode && hasInitialPreferences}
                  onClick={addressDialogModalState.openModal}
                >
                  {addressObject ? "Edit Address" : "Add Address"}
                </Button>
              </Stack>
              {addressObject && (
                <Text variant="body2">
                  {formatDistanceInMiles(addressObject.distance)} from{" "}
                  {addressObject.address.formatted}
                </Text>
              )}
              {errors.addressObject && (
                <Text color="error" variant="caption">
                  {errors.addressObject.message}
                </Text>
              )}
            </Stack>
            {(!hasInitialPreferences || isEditMode || isSubmitting) && (
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                  size="small"
                  variant="text"
                  onClick={() => {
                    setIsEditMode(false);
                    reset({
                      jobTypes: initialJobTypes,
                      shiftTypes: initialShiftTypes,
                      addressObject: initialAddressDetails,
                    });
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  color="primary"
                  size="small"
                  variant="contained"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </Stack>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
      <AddressSelectionDialog
        modalState={addressDialogModalState}
        initialAddressDetails={{
          address: addressObject?.address ?? worker.address,
          location: {
            lng: addressObject?.location.coordinates[0] ?? worker.geoLocation?.coordinates[0],
            lat: addressObject?.location.coordinates[1] ?? worker.geoLocation?.coordinates[1],
          },
          distance: addressObject?.distance ?? worker.preference.distance,
        }}
        onAddressSelect={handleAddressSelect}
      />
    </form>
  );
}
